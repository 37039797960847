import { interpolateEasing } from './interpolateEasing';

const table = [
    0.0005, 0.0020, 0.0045, 0.0078, 0.0119, 0.0168,
    0.0224, 0.0286, 0.0355, 0.0429, 0.0508, 0.0592, 0.0681,
    0.0774, 0.0870, 0.0969, 0.1072, 0.1177, 0.1285, 0.1395,
    0.1507, 0.1621, 0.1736, 0.1853, 0.1970, 0.2089, 0.2208,
    0.2328, 0.2448, 0.2569, 0.2689, 0.2810, 0.2930, 0.3050,
    0.3170, 0.3289, 0.3408, 0.3526, 0.3644, 0.3760, 0.3876,
    0.3991, 0.4105, 0.4218, 0.4330, 0.4440, 0.4550, 0.4658,
    0.4765, 0.4871, 0.4975, 0.5078, 0.5180, 0.5280, 0.5379,
    0.5477, 0.5573, 0.5668, 0.5761, 0.5853, 0.5944, 0.6033,
    0.6120, 0.6206, 0.6291, 0.6374, 0.6456, 0.6537, 0.6616,
    0.6693, 0.6770, 0.6845, 0.6918, 0.6990, 0.7061, 0.7130,
    0.7199, 0.7265, 0.7331, 0.7395, 0.7458, 0.7520, 0.7580,
    0.7640, 0.7698, 0.7755, 0.7810, 0.7865, 0.7918, 0.7971,
    0.8022, 0.8072, 0.8121, 0.8169, 0.8216, 0.8262, 0.8307,
    0.8351, 0.8394, 0.8436, 0.8477, 0.8517, 0.8557, 0.8595,
    0.8633, 0.8669, 0.8705, 0.8740, 0.8775, 0.8808, 0.8841,
    0.8873, 0.8904, 0.8934, 0.8964, 0.8993, 0.9022, 0.9049,
    0.9076, 0.9103, 0.9129, 0.9154, 0.9178, 0.9202, 0.9226,
    0.9249, 0.9271, 0.9293, 0.9314, 0.9335, 0.9355, 0.9375,
    0.9394, 0.9413, 0.9431, 0.9449, 0.9466, 0.9483, 0.9500,
    0.9516, 0.9532, 0.9547, 0.9562, 0.9576, 0.9591, 0.9605,
    0.9618, 0.9631, 0.9644, 0.9657, 0.9669, 0.9681, 0.9692,
    0.9703, 0.9714, 0.9725, 0.9736, 0.9746, 0.9756, 0.9765,
    0.9775, 0.9784, 0.9793, 0.9802, 0.9810, 0.9818, 0.9826,
    0.9834, 0.9842, 0.9849, 0.9856, 0.9863, 0.9870, 0.9877,
    0.9883, 0.9890, 0.9896, 0.9902, 0.9908, 0.9913, 0.9919,
    0.9924, 0.9930, 0.9935, 0.9940, 0.9944, 0.9949, 0.9954,
    0.9958, 0.9963, 0.9967, 0.9971, 0.9975, 0.9979, 0.9983,
    0.9986, 0.9990, 0.9993, 0.9997, 1.0000
];

export const spring = interpolateEasing(table);
