import { interpolateEasing } from './interpolateEasing';

const table = [
    0.0021, 0.0045, 0.0071, 0.0100, 0.0131, 0.0165, 0.0202,
    0.0242, 0.0284, 0.0330, 0.0378, 0.0429, 0.0483, 0.0540,
    0.0601, 0.0664, 0.0731, 0.0800, 0.0872, 0.0948, 0.1026,
    0.1108, 0.1192, 0.1279, 0.1369, 0.1461, 0.1556, 0.1653,
    0.1753, 0.1855, 0.1958, 0.2064, 0.2171, 0.2279, 0.2389,
    0.2500, 0.2612, 0.2725, 0.2839, 0.2952, 0.3067, 0.3181,
    0.3295, 0.3410, 0.3524, 0.3637, 0.3750, 0.3863, 0.3974,
    0.4085, 0.4195, 0.4304, 0.4412, 0.4519, 0.4624, 0.4729,
    0.4832, 0.4934, 0.5034, 0.5133, 0.5231, 0.5327, 0.5422,
    0.5516, 0.5608, 0.5699, 0.5788, 0.5876, 0.5963, 0.6048,
    0.6132, 0.6214, 0.6295, 0.6375, 0.6453, 0.6530, 0.6606,
    0.6680, 0.6754, 0.6825, 0.6896, 0.6965, 0.7034, 0.7101,
    0.7166, 0.7231, 0.7295, 0.7357, 0.7418, 0.7479, 0.7538,
    0.7596, 0.7653, 0.7709, 0.7764, 0.7818, 0.7871, 0.7923,
    0.7974, 0.8024, 0.8073, 0.8122, 0.8169, 0.8216, 0.8261,
    0.8306, 0.8350, 0.8394, 0.8436, 0.8478, 0.8518, 0.8558,
    0.8598, 0.8636, 0.8674, 0.8711, 0.8747, 0.8783, 0.8818,
    0.8852, 0.8886, 0.8919, 0.8951, 0.8983, 0.9014, 0.9044,
    0.9074, 0.9103, 0.9131, 0.9159, 0.9187, 0.9213, 0.9240,
    0.9265, 0.9290, 0.9315, 0.9339, 0.9362, 0.9385, 0.9408,
    0.9430, 0.9451, 0.9472, 0.9492, 0.9512, 0.9532, 0.9551,
    0.9569, 0.9587, 0.9605, 0.9622, 0.9638, 0.9655, 0.9670,
    0.9686, 0.9701, 0.9715, 0.9729, 0.9743, 0.9756, 0.9769,
    0.9782, 0.9794, 0.9805, 0.9817, 0.9828, 0.9838, 0.9848,
    0.9858, 0.9868, 0.9877, 0.9886, 0.9894, 0.9902, 0.9910,
    0.9917, 0.9924, 0.9931, 0.9937, 0.9943, 0.9949, 0.9954,
    0.9959, 0.9964, 0.9969, 0.9973, 0.9977, 0.9980, 0.9983,
    0.9986, 0.9989, 0.9991, 0.9993, 0.9995, 0.9997, 0.9998,
    0.9999, 0.9999, 1.0000, 1.0000
];

export const ease = interpolateEasing(table);
